.main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.back{
    position: absolute;
    left: 5%;
    bottom:8%;
    
}
.image{
    width: 512px;
    height: 512px;
    border-style: solid;
    border-color: black;
    position: relative;
}

.prompt{
    margin-top: 10px;
    margin-bottom: 3vh;
    width: 600px;
}
.buttonbox{
    display: flex;
    justify-content: space-between;
    width: 30%;
}
.canvas{
    height: 512px;
    width: 512px;
    border-style: solid;
    border-color: black;
}

.imagebox{
    display: flex;
    direction: row;
    width: 70vw;
    justify-content: space-around;
}

.spinner{
    margin-top: 20px;
}

.imagetext{
    display: relative;
    margin-top: -7.5%;
}

.edit{
    display: flex;
    margin-left: 60%;

}

