.imagelibrary{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.image-database{
    width: 70%;
    height: 70%;
    overflow-y: scroll;
    padding: 10px;
    border-style: groove;
    margin: 2%;
    border-width: 5px;
}
.image-database::-webkit-scrollbar{
    display: none;
}
.space{
    margin: 10px;
}

.intro{
    margin-bottom: 5vh;
}

.confirm-box{
    width:30vw;
    height: 80vh;
    margin-right: 3%;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    overflow: scroll;
}
.confirm-box::-webkit-scrollbar{
    display: none;
}

.downspace{
    margin-bottom: 20px;
}

.reset{
    position: absolute;
    top: 5%;
    right: 5%;
}