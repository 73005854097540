.history{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.images{
    height: 80vh;
    width: 80vw;
    overflow-y: scroll;
}
.buttonspace{
    margin: 20px;
}
